// @flow
import * as React from 'react'
import styled from 'styled-components'
import { config } from 'react-awesome-styled-grid'
import {
  iOSBranchLink,
  AndroidBranchLink
} from '../helpers/utils'
import { colors } from '../components/shared/colors'
import { OutlineOnFocusVisible } from '../components/shared/buttons'
import Link from 'next/link'

type PropsType = {
  isInverse?: boolean,
  containerStyles?: any,
  backgroundColor?: string,
  alignLeft?: boolean,
  color?: string,
  signup?: boolean,
  webApp?: boolean,
  badgesOnly?: boolean
}

export default function Badges (props: PropsType): React.Node {
  return (
    <BadgeContainerWrapper containerStyles={props.containerStyles}>
      {!props.badgesOnly && <WebAppContainer alignLeft={props.alignLeft}>
        <WebAppText color={props.color}>
          Or download the Dipsea app for the best listening experience.
        </WebAppText>
      </WebAppContainer>}
      <Container alignLeft={props.alignLeft} className='badge-container'>
        <a
          data-delay='200'
          href={iOSBranchLink}
          rel='noopener noreferrer'
          onClick={(): void => {
            // props.trackingEvent()
          }}
        >
          <Badge
            backgroundColor={props.backgroundColor}
            src='/images/app-store-badge.png'
            alt='Dipseas on apple store'
          />
        </a>
        <a
          data-delay='200'
          href={AndroidBranchLink}
          rel='noopener noreferrer'
          onClick={(): void => {
            // props.trackingEvent()
          }}
        >
          <Badge
            backgroundColor={props.backgroundColor}
            src='/images/google-play-badge.png'
            alt='Dipseas on Google play store'
          />
        </a>
      </Container>
      {props.webApp !== false && <WebAppContainer alignLeft={props.alignLeft}>
        <WebAppText color={props.color}>
          Or <Link href={'/discover'}>listen to Dipsea</Link> on any browser.
        </WebAppText>
      </WebAppContainer>}
    </BadgeContainerWrapper>
  )
}

const BadgeContainerWrapper = styled.div`
  ${(props: any): ?string => props.containerStyles && props.containerStyles}
`
const WebAppContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  text-align: center;
  ${(props: any): string =>
    props.alignLeft &&
    config(props).media.lg`
    justify-content: unset;
    text-align: left;
  `}
`
const WebAppText = styled.p`
  color: ${(props: any): string => props.color || colors.sand};
  font-family: 'Good Sans Light';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 125%;
  margin: 0;
  > a {
    border-bottom: 1px solid
      ${(props: any): string => props.color || colors.sand};
    padding: 0 0 2px;
    color: ${(props: any): string => props.color || colors.sand};
    text-decoration: none;
    outline: none;
    &:hover,
    &:focus {
      opacity: 1;
      color: ${colors.orange};
      border-bottom: 1px solid ${colors.orange};
      transition: all 0.3s;
    }
  }
`
const Container = styled.div`
  align-content: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: -8px 2px 12px;
  vertical-align: top;
  a {
    align-items: flex-start; 
    border-radius: 7px;
    cursor: pointer;
    display: flex;
    margin: 8px;
    ${OutlineOnFocusVisible};
    &:hover {
      opacity: 0.8;
    }
  }

  ${(props: any): string => config(props).media.sm`
    margin: -8px 12px 12px;
  `}
  ${(props: any): string => config(props).media.md`
    margin: -8px 0 14px;
  `}
  ${(props: any): string => config(props).media.lg`
    margin: -8px -8px 32px;
    justify-content:  ${props.alignLeft ? 'flex-start' : 'center'};
  `}
`

const Badge = styled.img`
  display: block;
  max-height: 42px;
  width: auto;
  ${(props: any): string => config(props).media.lg`
     height: 46px;
  `}
  background-color: ${(props: any): string =>
    props.backgroundColor ? props.backgroundColor : 'transparent'};
`
