// @flow
import * as React from 'react'
import styled from 'styled-components'
import { colors } from './shared/colors'
import { DownArrow } from './shared/icons'
import { OutlineOnFocusVisible } from './shared/buttons'

export const Accordion = (props: {
  accordionContent: { key: string, title: string, body: React.Node }[]
}): React.Node => {
  const [active, setActive] = React.useState(null)
  return (
    <Container>
      {props.accordionContent.map(
        (item: {
          key: string,
          title: string,
          body: React.Node
        }): React.Node => {
          const isActive = active === item.key
          return (
            <AccordionContainer key={item.key}>
              <Button
                onClick={(): void => {
                  if (isActive) {
                    setActive(null)
                  } else {
                    setActive(item.key)
                  }
                }}
              >
                <SectionTitle>{item.title}</SectionTitle>
                <ArrowContainer isActive={isActive}>
                  <DownArrow size={20} />
                </ArrowContainer>
              </Button>

              <Pannel isActive={isActive}>{item.body}</Pannel>
            </AccordionContainer>
          )
        }
      )}
    </Container>
  )
}

const Container = styled.div`
  margin: 40px 0 30px;
`
const linkStyle = `
  text-decoration: none;
  cursor: pointer;
  color: ${colors.sand};
  font-family: "Good Sans Light";
  font-size: 14px;
  outline: none;
  border: 1px solid transparent;
  transition: all 0.3s;
  :hover {
    color: ${colors.orange};
    opacity: 1;
  }
  :focus-visible {
    color: ${colors.orange};
    opacity: 1;
    border-bottom: 1px solid ${colors.orange};
  }
`
const AccordionContainer = styled.div`
  border-bottom: 1px solid ${colors.sand};
  overflow: hidden;
  ul {
    margin: 0;
    padding: 0 0 0.5em;
    li {
      display: list-item;
      text-align: -webkit-match-parent;
      list-style: none;
      padding: 0;
      margin: 0 0 10px;
      a {
        ${linkStyle};
        font-size: 11px;
      }
    }
  }
`
const SectionTitle = styled.p`
  color: ${colors.sand};
  font-size: 14px;
  font-family: "Good Sans Medium";
  letter-spacing: 0.03em;
`
const Button = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 42px;
  background: none;
  justify-content: space-between;
  outline: none;
  border: none;
  border-radius: 2px;
  padding: 0;
  ${OutlineOnFocusVisible};
  transition: 0.4s;

  :hover {
    opacity: 0.7;
  }
`
const Pannel = styled.div`
  overflow: hidden;
  ${(props: { isActive: boolean }): ?string => {
    if (props.isActive) {
      return `
        display: flex;
        animation: open 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        max-height: 400px;
        @keyframes open {0% { max-height: 0; opacity: 0;} 100% { max-height: 400px; opacity: 1;}
      `
    } else {
      return `display: none;`
    }
  }}
`
const ArrowContainer = styled.div`
  ${(props: { isActive: boolean }): ?string => {
    if (props.isActive) {
      return `-webkit-transform: scaleY(-1); transform: scaleY(-1);`
    }
  }}
`
