// @flow
import {
  isMobile
} from 'react-device-detect'
import { isWebView } from '../actions/loadActions'

export const openAppStore = (): void => {
  return window.open('https://dipsea.app.link/p5NWn7IEZY', '_blank')
}

export const openLink = (url: string): void => {
  let newUrl = url
  if (window && window.location.search.includes('utm') && !url.includes('?')) {
    newUrl = newUrl + window.location.search
  }
  return window.open(newUrl, '_blank')
}

export const iOSBranchLink = 'https://apps.apple.com/app/dipsea-sexy-audio-stories/id1434242889'

export const AndroidBranchLink = 'https://play.google.com/store/apps/details?id=com.dipsea'

export const AppStoreHref = isMobile ? 'https://apps.apple.com/app/dipsea-sexy-audio-stories/id1434242889' : iOSBranchLink

export const PlayStoreHref = isMobile ? 'https://play.google.com/store/apps/details?id=com.dipsea' : AndroidBranchLink

export const WebAppHref = 'https://www.dipseastories.com/discover'

export const WebAppSignInHref = 'https://www.dipseastories.com/discover'
export const WebAppSignUpHref = 'https://app.dipseastories.com/signup'

export const isChromeForIOs145 = (): boolean => {
  if (typeof window !== 'undefined') {
    if (isWebView()) {
      return true
    }
    const userAgent = window.navigator.userAgent
    const isChromeForIOs = /CriOS/i.test(userAgent)
    if (isChromeForIOs) {
      const iOsMatch = userAgent.match(
        /(.+)(iPhone|iPad|iPod)(.+)OS[\s|\_](\d+)\_?(\d+)?[\_]?(\d+)?.+/i
      )
      if (iOsMatch && iOsMatch.length >= 6) {
        const iOsVersionMajor = parseInt(iOsMatch[4], 10)
        const iOsVersionMinor = parseInt(iOsMatch[5], 10)
        if (iOsVersionMajor >= 14 && iOsVersionMinor >= 5) {
          return true
        }
      }
    }
  }

  return false
}
